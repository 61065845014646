import * as React from 'react';
import { getSystemText, getDate, getMenu, AgentValidate, GetDynamicPage2, GetSiteMapRoutes } from '../routes/store/flight';
import { getControlText, getLanguageID } from '../routes/shared/functions';
import { connect } from 'react-redux';

import { withRouter } from 'react-router';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import { frontloadConnect } from 'react-frontload';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { setMenu, setSystemText, setProp } from '../../modules/general';

function getLoginUser() {
  const userJson = Cookies.get('Login');
  return userJson !== null && userJson !== undefined ? JSON.parse(userJson) : null;
}

function saveCurrentUser(user) {
  Cookies.set('cookiekabul', JSON.stringify(user));
}

function getCurrentUser() {
  const userJson = Cookies.get('cookiekabul');
  return userJson !== null && userJson !== undefined ? JSON.parse(userJson) : null;
}

let _timer = 0;
async function frontload(props) {
  let lang = getLanguageID();
  
  let p1 = new Promise((resolve, reject) => {
    getMenu(163, 3, 'vlig').then(val => {
       
      if (val && val.data && val.data.d) {
        props.setMenu(val.data.d, 'vlig');
      }
      resolve('ok');
    });
  });

  let p2 = new Promise((resolve, reject) => {
    getMenu(164, 3, 'maat').then(val2 => {
      if (val2 && val2.data && val2.data.d) {
        props.setMenu(val2.data.d, 'maat');
      }
      resolve('ok');
    });
  });

  let p6 = new Promise((resolve, reject) => {
    getMenu(221, 3, 'reg').then(val2 => {
      if (val2 && val2.data && val2.data.d) {
        props.setMenu(val2.data.d, 'reg');
      }
      resolve('ok');
    });
  });

  let p3 = new Promise((resolve, reject) => {
    getSystemText('16', lang, 'Nav').then(val3 => {
      props.setProp(lang, 'languageID');
      resolve('ok');
    });
  });

  let p4 = new Promise((resolve, reject) => {
    getDate('Datetime').then(val4 => {
      props.setSystemText(val4.data.d, 'Datetime');
      resolve('ok');
    });
  });

  let promise = Promise.all([p1, p2, p3, p4, p6]).then(values => {});

  
}

class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
      savedcook: false,
      saatfark: 0,
      timer: null,
      counter: 0,
      timeRemainingInSeconds: 0,
      officeactive: '',
      remember: false,
      placeHolderDep: '',
      placeHolderArr: '',
      Departure: 0,
      Arrival: 0,
      departureID: '',
      arrivalID: '',
      baby: '',
      adult: '',
      child: '',
      Passengercount: 0,
      startDate: '',
      endDate: '',
      Direct: '',
      oneway: '',
      arrivalCountry: '',
      arrivalCity: '',
      departureCountry: '',
      departureCity: '',
      valarr: '',
      valdep: '',
      uuName: '',
      uuSurname: '',
      languageID: props.languageID,
      mobmen: false
    };
    this.getRedirectObjectdetailv2 = this.getRedirectObjectdetailv2.bind(this);
  }

  decrementTimeRemaining() {
    if (this.state.timeRemainingInSeconds > 0) {
      this.setState({
        timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1
      });
    } else {
      clearInterval(_timer);
    }
  }

  componentDidMount() {
    

    this.onDepartureFocus = this.onDepartureFocus.bind(this);

    _timer = setInterval(() => {
      this.decrementTimeRemaining();
    }, 1000);

    if (getCurrentUser()) {
      this.setState({
        savedcook: true
      });
    }

    
    
    
    
    

    
    
    
    
    
    
    
    
    
    

    let _loginData = Cookies.get('Login');
    let loginData = _loginData ? JSON.parse(_loginData) : {};
    if (loginData && loginData.username && loginData.key) {
      

      if (loginData.key) {
        if (loginData.key.agent) {
          this.setState({ uuName: loginData.key.agent.Name, uuSurname: loginData.key.agent.Surname });
        }

        if (loginData.key.client) {
          this.setState({ uuName: loginData.key.client.Name, uuSurname: loginData.key.client.Surname });
        }
        
      }
    }
  }

  toggleRemember(durum) {
    this.setState({
      remember: durum
    });
  }

  togglemob(durum) {
    this.setState({
      mobmen: durum
    });
  }

  languageChange(languageID) {
    Cookies.set('languageID', languageID.toString());

    let _path = this && this.props && this.props.location && this.props.location.pathname;

    if(typeof window !== "undefined")
      window.location.reload();
  }

  onDepartureFocus() {
    this.setState({
      remember: true
    });
  }

  logincookie() {
    let user = { cookiekabul: 'ok' };

    saveCurrentUser(user);
    this.setState({
      savedcook: true
    });
  }

  logoutuser() {
    Cookies.remove('Login');
    if(typeof windows !== "undefined")
      window.location.reload();
  }

  getRedirectObject(url, fromID, toID) {
    let redTo = {
      pathname: url,

      state: {
        SearchType: '1',
        FlightDate: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        RetFlightDate: moment()
          .add(8, 'day')
          .format('YYYY-MM-DD'),
        FromAirport: fromID,
        ToAirport: toID,
        oneway: '0',
        Adult: 1,
        Child: 0,
        Baby: 0,
        OnlyDirect: 1,
        From: '',
        To: ''
      }
    };
    return redTo;
  }

  getRedirectObjectdetail(url, fromID, toID, startdate, enddate, oneway, adult, child, baby, direct) {
    let redTo = {
      pathname: url,
      state: {
        SearchType: '1',
        FlightDate: moment(startdate).format('YYYY-MM-DD'),
        RetFlightDate: moment(enddate).format('YYYY-MM-DD'),
        FromAirport: fromID,
        ToAirport: toID,
        oneway: oneway ? '0' : '1',
        Adult: adult,
        Child: child,
        Baby: baby,
        OnlyDirect: direct ? 1 : 0,
        From: '',
        To: ''
      }
    };

    return redTo;
  }

  getRedirectObjectdetailv2(url, fromID, toID, startdate, enddate, oneway, adult, child, baby, direct) {
    let redTo = {
      pathname: url,
      state: {
        SearchType: '1',
        FlightDate: moment(startdate), 
        RetFlightDate: moment(enddate), 
        FromAirport: fromID,
        ToAirport: toID,
        oneway: oneway === 1 || oneway === true || oneway === '1' ? 1 : 0,
        Adult: adult,
        Child: child,
        Baby: baby,
        OnlyDirect: direct ? 1 : 0,
        From: '',
        To: ''
      }
    };

    this.props.setProp(redTo, 'selectedHistory').then(() => {
      this.toggleRemember(false);
    });
  }

  linkSend(url, fromID, toID) {
    let dataPush = this.getRedirectObject(url, fromID, toID);
    this.props.history.push(dataPush);
    if(typeof window !== "undefined")
      window.location.href = url;
  }

  changeName = () => {
    this.setState({
      name: 'Bruce Wayne'
    });
  };

  render() {
    const langID = this.props && this.props.general && this.props.general.languageID ? this.props.general.languageID : 77;
    let datalang = Cookies.get('languageID');

    let mTitle = { title: '', Url: '' };
    let mTitlereg = { title: '', Url: '' };

    if (this.props.sitemap && this.props.sitemap.length > 0) {
      let maas = this.props.sitemap.filter(function(valueOf) {
        return valueOf.PageContentID == 377;
      });

      let reg = this.props.sitemap.filter(function(valueOf) {
        return valueOf.PageContentID == 392;
      });

      if (reg && reg.length > 0) {
        mTitlereg = reg[0];
      }
    }

    let hlTopHome = '';
    let hlTopFlight = '';
    let hlTopRentacar = '';
    let hlTopReservation = '';
    let hlTopAgent2_1 = '';
    let hlTopContact = '';
    let ltrSelectLang = '';
    let flagID = '';
    let hlTopAgent2 = '';
    let hlTopAgent2_2 = '';
    let hlTopAgent2_3 = '';
    let hlMaatschappijen = '';
    let hlBestemmingen = '';
    let hlVliegvelden = '';
    let yazi = '';
    let buttontikla = '';
    let hlTopLogin = '';
    let hlTopLogout = '';
    let tarih = '';
    let tarihonoff = '';

    let t1017 = '';
    let t0810 = '';
    let t1516 = '';
    let t1617 = '';
    let t1700 = '';
    let t0008 = '';
    let thaftasonu = '';
    let passengertext = '',
      setrecent = '',
      hlsonaramalar = '',
      txt_passzero = '',
      myinfo = '',
      myres = '',
      mybenefits = '',
      hlregion = '';

    if (this.props && this.props.general) {
      let dataText = [];
      myinfo = getControlText('1039', dataText).Text;
      myres = getControlText('1040', dataText).Text;
      mybenefits = getControlText('1041', dataText).Text;

      t1017 = getControlText('971', dataText).Text;
      t0810 = getControlText('965', dataText).Text;
      t1516 = getControlText('966', dataText).Text;
      t1617 = getControlText('967', dataText).Text;
      t1700 = getControlText('968', dataText).Text;
      t0008 = getControlText('969', dataText).Text;
      thaftasonu = getControlText('972', dataText).Text;

      hlTopHome = getControlText('41', dataText).Text;

      hlTopAgent2_1 = getControlText('586', dataText).Text;
      hlTopContact = getControlText('46', dataText).Text;
      ltrSelectLang = getControlText('416', dataText).Text;

      hlMaatschappijen = getControlText('909', dataText).Text;

      hlregion = getControlText('1145', dataText).Text;

      yazi = getControlText('1028', dataText).Text;
      buttontikla = getControlText('1029', dataText).Text;

      hlTopLogin = getControlText('1007', dataText).Text;
      hlTopLogout = getControlText('553', dataText).Text;
      passengertext = getControlText('998', dataText).Text;
      setrecent = getControlText('999', dataText).Text;
      hlsonaramalar = getControlText('1027', dataText).Text;
      txt_passzero = getControlText('552', dataText).Text;
    }

    const menuItems = [];

    if (this.props && this.props.general && this.props.general.menuvlig) {
      this.props.general.menuvlig.forEach(valueof => {
        let subItems = [];
        valueof.Items.forEach(valOfMenuItem => {
          subItems.push(
            <li>
              <Link
                to={this.getRedirectObject(valOfMenuItem.Url, valOfMenuItem.DepartureID, valOfMenuItem.ArrivalID)}
                title={valOfMenuItem.Title}
                alt={valOfMenuItem.Title}>
                <span onClick={() => this.linkSend(valOfMenuItem.Url, valOfMenuItem.DepartureID, valOfMenuItem.ArrivalID)}>
                  {valOfMenuItem.Title}
                </span>
              </Link>
            </li>
          );
        });

        menuItems.push(
          <li>
            {subItems.length !== 0 && (
              <label htmlFor={'drop-' + valueof.ID} className="toggle">
                {' '}
                {valueof.Title}
              </label>
            )}{' '}
            <a href={valueof.Url} title={valueof.Title} alt={valueof.Title}>
              {valueof.Title}
            </a>
            {subItems.length !== 0 && <input type="checkbox" id={'drop-' + valueof.ID} />}
            {subItems.length !== 0 && <ul className="_dropdown">{subItems}</ul>}
          </li>
        );
      });
    }

    const menuItems2 = [];
    const menuItems3 = [];

    if (this.props && this.props.general && this.props.general.menumaat) {
      this.props.general.menumaat.forEach(valueof => {
        let subItems = [];
        valueof.Items.forEach(valOfMenuItem => {
          subItems.push(
            <li>
              <Link
                alt={valOfMenuItem.Title}
                title={valOfMenuItem.Title}
                to={this.getRedirectObject(valOfMenuItem.Url, valOfMenuItem.DepartureID, valOfMenuItem.ArrivalID)}>
                <span onClick={() => this.linkSend(valOfMenuItem.Url, valOfMenuItem.DepartureID, valOfMenuItem.ArrivalID)}>
                  {valOfMenuItem.Title}
                </span>
              </Link>
            </li>
          );
        });

        menuItems2.push(
          <li>
            <label htmlFor={'drop-' + valueof.ID} className="toggle">
              {' '}
              {valueof.Title}
            </label>{' '}
            <a href={valueof.Url} alt={valueof.Title} title={valueof.Title}>
              {valueof.Title}
            </a>
            <input type="checkbox" id={'drop-' + valueof.ID} />
            <ul className="_dropdown">{subItems}</ul>
          </li>
        );
      });
    }

    if (this.props && this.props.general && this.props.general.menureg) {
      this.props.general.menureg.forEach(valueof => {
        menuItems3.push(
          <li>
            <a href={valueof.Url} alt={valueof.Title} title={valueof.Title}>
              {valueof.Title}
            </a>
          </li>
        );
      });
    }

    if (this.props && this.props.general && this.props.general.systemTextDatetime) {
      //var ServerTime = moment(this.props.general.systemTextDatetime.toString());
      const localTime = new Date();
      function isDaylightSavingTime(date) {
        const startDST = new Date(date.getFullYear(), 2, (31 - new Date(date.getFullYear(), 2, 31).getDay())); // Mart'ın son Pazar günü
        const endDST = new Date(date.getFullYear(), 9, (31 - new Date(date.getFullYear(), 9, 31).getDay())); // Ekim'in son Pazar günü
      
        return date >= startDST && date < endDST;
      }
      const localOffset = localTime.getTimezoneOffset() / 60;
      let netherlandsTimeOffset = 1;
      if (isDaylightSavingTime(localTime)) {
        netherlandsTimeOffset = 2; // Yaz saati uygulamasında UTC+2
      }
      const netherlandsTime = new Date(localTime.getTime() + (localOffset + netherlandsTimeOffset) * 60 * 60 * 1000);

      var ServerTime = moment(netherlandsTime);
      let stim = parseInt(ServerTime.format('H'));
      let tgun = ServerTime.day();

      if (tgun === 0 || tgun === 6) {
        tarih = thaftasonu;
        tarihonoff = '_office-date-off';
      } else if (tgun === 10) {
        if (stim >= 17 && stim < 24 && tgun === 6) {
          tarih = thaftasonu;
          tarihonoff = '_office-date-off';
        } else if (stim >= 8 && stim < 11) {
          tarih = t0810;
          tarihonoff = '_office-date-off';
        } else if (stim >= 11 && stim < 15) {
          tarih = t1017;
          tarihonoff = '_office-date-on';
        } else if (stim == 14) {
          tarih = t1516;
          tarihonoff = '_office-date-on';
        } else if (stim == 15) {
          tarih = t1617;
          tarihonoff = '_office-date-on';
        } else if (stim > 15 && stim < 25) {
          tarih = t1700;
          tarihonoff = '_office-date-off';
        } else if (stim >= 0 && stim < 8) {
          tarih = t0008;
          tarihonoff = '_office-date-off';
        }
      } else {
        if (stim >= 8 && stim < 11) {
          tarih = t0810;
          tarihonoff = '_office-date-off';
        } else if (stim >= 11 && stim < 15) {
          tarih = t1017;
          tarihonoff = '_office-date-on';
        } else if (stim == 14) {
          tarih = t1516;
          tarihonoff = '_office-date-on';
        } else if (stim == 15) {
          tarih = t1617;
          tarihonoff = '_office-date-on';
        } else if (stim > 15 && stim < 25) {
          tarih = t1700;
          tarihonoff = '_office-date-off';
        } else if (stim >= 0 && stim < 8) {
          tarih = t0008;
          tarihonoff = '_office-date-off';
        }
      }
    }

    var RememberSearchCheck = undefined;

    RememberSearchCheck = Cookies.get('rs');

    

    if (RememberSearchCheck) {
      var arr = RememberSearchCheck ? JSON.parse(RememberSearchCheck) : [];

      

      if (arr.length > 0) {
        arr
          .filter(function(valueOf) {
            return moment(arr.s) < moment(valueOf.s);
          })
          .reverse()
          .forEach(o => {
            if (o.f === undefined) {
              Cookies.remove('rs');
            }
          });
      }
    }

    var RememberSearch1 = undefined;
    RememberSearch1 = Cookies.get('rs');

    
    var dizim = [];

    if (RememberSearch1 && this.props && this.props.general && this.props.general.airports && this.props.general.airports.length > 0) {
      var arr = RememberSearch1 ? JSON.parse(RememberSearch1) : [];
      if (arr.length > 0) {
        arr
          .filter(function(valueOf) {
            return moment(arr.s) < moment(valueOf.s);
          })
          .reverse()
          .forEach(o => {
            let sonucDep = _.find(this.props.general.airports, function(p) {
              return p.ID == o.f;
            });
            let sonucArr = _.find(this.props.general.airports, function(p) {
              return p.ID == o.t;
            });

            let urlsend = '/vliegtickets/' + sonucDep.City.toLowerCase().replace(' ', '') + '/' + sonucArr.City.toLowerCase().replace(' ', '');

            dizim.push(
              <div className="remember-item" onClick={() => this.getRedirectObjectdetailv2(urlsend, o.f, o.t, o.s, o.e, o.o, o.a, o.c, o.b, o.n)}>
                <div className="row _pl-10 _pb-5">
                  <div className="col-xs-12 blfd_content ">
                    <div className="text-center ">
                      {moment(o.s).format('ddd DD MMM YYYY')} {o.e != 'Invalid date' ? '- ' + moment(o.e).format('ddd DD MMM YYYY') : ''} ,{' '}
                      {parseInt(o.a) + parseInt(o.c) + parseInt(o.b)} {passengertext}
                    </div>
                  </div>
                </div>
                <div className="row  _pl-10">
                  <div className="col-xs-12 ">
                    <div className="_pb-5">
                      <i className="fa fa-plane " /> <span className=" _pl-5">{sonucDep.Name}</span>{' '}
                    </div>
                    <div className="soft-color ">
                      <i className="fa fa-plane  return_icon" /> <span className=" _pl-5">{sonucArr.Name}</span>
                    </div>
                  </div>
                </div>
                <hr className="ozel_row" />{' '}
              </div>
            );
          });
      }
    }

    return (
      <div>
        {' '}
        <div className="eskisite">
          {' '}
          {yazi}{' '}
          <a href="https://www.triptime.nl/coronavirus" alt="coronavirus" className="btn btn-danger">
            {buttontikla}
          </a>
        </div>{' '}
        {this.getbar() === 'no' ? (
          ''
        ) : (
          <nav className="navbar navbar-default navbar-inverse navbar-theme  navbar-theme-border">
            <div className="container">
              <div className="navbar-inner nav ">
                <nav>
                  <div id="logo">
                    <a className="navbar-brand" href="/" alt="Triptime.nl">
                      <img src="/img/logo.png" alt="Triptime.nl" title="Triptime.nl" />
                    </a>
                  </div>

                  <label htmlFor="drop" className="toggle sil">
                    {' '}
                    <i className="fa fa-bars" />{' '}
                  </label>
                  <input type="checkbox" id="drop" />

                  <ul className="menu ">
                    <li>
                      <a href="/">{hlTopHome}</a>
                    </li>

                    <li>
                      <label htmlFor="drop-2" className="toggle">
                        Vliegtickets
                      </label>
                      <a href="/vliegtickets/turkije">Vliegtickets</a>
                      <input type="checkbox" id="drop-2" />
                      <ul>{menuItems}</ul>
                    </li>
                    <li>
                      <label htmlFor="drop-3" className="toggle">
                        Last minute
                      </label>
                      <a href="/last-minute/turkije" alt="Last minute">
                        Last minute
                      </a>
                      <input type="checkbox" id="drop-3" />
                      <ul>
                        <li>
                          <a href="/last-minute/turkije/dalaman" alt="Last minute Dalaman">
                            Last minute Dalaman
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/izmir" alt="Last minute Izmir">
                            Last minute Izmir
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/antalya" alt="Last minute Antalya">
                            Last minute Antalya
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/alanya" alt="Last minute Alanya">
                            Last minute Alanya
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/bodrum" alt="Last minute Bodrum">
                            Last minute Bodrum
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/kayseri" alt="Last minute Kayseri">
                            Last minute Kayseri
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/konya" alt="Last minute Konya">
                            Last minute Konya
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/ankara" alt="Last minute Ankara">
                            Last minute Ankara
                          </a>
                        </li>
                        <li>
                          <a href="/last-minute/turkije/istanbul" alt="Last minute Istanbul">
                            Last minute Istanbul
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li>
                      <label htmlFor="drop-4" className="toggle">
                        {hlMaatschappijen}{' '}
                      </label>
                      <a href={'/' + mTitle.Url} alt="maatschappijen">
                        {hlMaatschappijen}
                      </a>
                      <input type="checkbox" id="drop-4" />
                      <ul>{menuItems2}</ul>
                    </li>
                    <li>
                      <label htmlFor="drop-6" className="toggle">
                        {hlregion}{' '}
                      </label>
                      <a href={'/' + mTitlereg.Url} alt="Region">
                        {hlregion}
                      </a>
                      <input type="checkbox" id="drop-6" />
                      <ul>{menuItems3}</ul>
                    </li>

                    <li className="_mob-s _desk-h">
                      <a href="/blog" role="button" alt="Blog">
                        BLOG
                      </a>
                    </li>
                    <li className="_mob-s _desk-h">
                      <a href="/uw-reservering" role="button">
                        {hlTopAgent2_1}
                      </a>
                    </li>

                    <li className="_mob-s _desk-h">
                      <a href="/contact" role="button">
                        {hlTopContact}
                      </a>
                    </li>
                    <li className="_mob-s _desk-h">
                      <label htmlFor="drop-99" className="toggle">
                        {ltrSelectLang}{' '}
                      </label>
                      <a href="#" rel="nofollow">
                        <img src={'/img/flag_codes/' + langID + '.png'} className="language_img" alt={ltrSelectLang} /> {ltrSelectLang}
                      </a>
                      <input type="checkbox" id="drop-99" />
                      <ul>
                        <li onClick={() => this.languageChange(1)}>
                          <a alt="English(US)">
                            <img src="/img/flag_codes/1.png" className="language_img" alt="English(US)" />
                            English(US)
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(2)}>
                          <a alt="Deutsch">
                            <img src="/img/flag_codes/2.png" className="language_img" alt="Deutsch" />
                            Deutsch
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(3)}>
                          <a alt="Nederlands">
                            <img src="/img/flag_codes/3.png" alt="Nederlands" className="language_img" />
                            Nederlands
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(5)}>
                          <a alt="Türkçe">
                            <img src="/img/flag_codes/5.png" alt="Türkçe" className="language_img" />
                            Türkçe
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul className=" menu_ust _mob-h _desk-s">
                    <li>
                      <a href="/contact" role="button" rel="nofollow">
                        <div className={tarihonoff}>{tarih}</div>
                      </a>
                    </li>
                    <li>
                      <a role="button" onClick={this.onDepartureFocus} rel="nofollow">
                        <div className="_lastremember">
                          {hlsonaramalar} <div className="_bg-remember"> {dizim.length} </div>
                        </div>
                      </a>
                      {this.state.remember &&
                        dizim.length > 0 && (
                          <div className="quantity-selector-box-rem  " style={{ width: '320px' }}>
                            <div className="text-right closedrem">
                              <a className="btn btn-bg-default whitebold " onClick={() => this.toggleRemember(false)} rel="nofollow">
                                X
                              </a>
                            </div>
                            <div className="banner-baslik_rem">
                              <p className="quantity-selector-title-rem">{setrecent}</p>
                            </div>
                            <div className="remembersearch barstyle">{dizim}</div>
                          </div>
                        )}
                    </li>

                    <li>
                      <a href="/blog" role="button">
                        BLOG
                      </a>
                    </li>
                    <li>
                      <a href="/uw-reservering" role="button">
                        {hlTopAgent2_1}
                      </a>
                    </li>

                    <li>
                      <a href="/contact" role="button">
                        {hlTopContact}
                      </a>
                    </li>

                    <li>
                      <label htmlFor="drop-4" className="toggle">
                        {ltrSelectLang}{' '}
                      </label>
                      <a href="#" rel="nofollow">
                        <img src={'/img/flag_codes/' + langID + '.png'} className="language_img" alt={ltrSelectLang} /> {ltrSelectLang}
                      </a>
                      <input type="checkbox" id="drop-4" />
                      <ul>
                        <li onClick={() => this.languageChange(1)}>
                          <a rel="nofollow">
                            <img src="/img/flag_codes/1.png" className="language_img" alt="English" />
                            English
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(2)}>
                          <a rel="nofollow">
                            <img src="/img/flag_codes/2.png" className="language_img" alt="Deutsch" />
                            Deutsch
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(3)}>
                          <a rel="nofollow">
                            <img src="/img/flag_codes/3.png" className="language_img" alt="Nederlands" />
                            Nederlands
                          </a>
                        </li>
                        <li onClick={() => this.languageChange(5)}>
                          <a rel="nofollow">
                            <img src="/img/flag_codes/5.png" className="language_img" alt="Türkçe" />
                            Türkçe
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            {!getLoginUser() ? (
              <div className="_mob-s _desk-h">
                <a href="/login" rel="nofollow">
                  <div className=" _lastremember _p-10 _fs-lg ">
                    {' '}
                    <i className="fa fa-user" /> {hlTopLogin}
                  </div>
                </a>
              </div>
            ) : (
              <div className="_mob-s _desk-h">
                <div className="_lastremember _m-5 _p-5 " onClick={() => this.togglemob(!this.state.mobmen)} rel="nofollow">
                  <i className="fa fa-user" /> {this.state.uuName}
                </div>
                {this.state.mobmen ? (
                  <ul>
                    <li>
                      <a href="/user/myinfo">{myinfo}</a>
                    </li>

                    <li>
                      <a href="/user/password">{txt_passzero}</a>
                    </li>

                    <li>
                      <a onClick={() => this.logoutuser()} rel="nofollow">
                        {hlTopLogout}
                      </a>
                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            )}
            <div className="_mob-s _desk-h">
              <a href="/contact" role="button" rel="nofollow">
                <div className={tarihonoff}>{tarih}</div>
              </a>
            </div>
          </nav>
        )}{' '}
        {!this.state.savedcook && (
          <div className="cookie_saved">
            Deze site maakt gebruik van cookies om u beter van dienst te zijn. Door deze site te gebruiken gaat u akkoord met het gebruik van cookies.{' '}
            <a href="https://www.triptime.nl/cookie" rel="nofollow">
              Lees verder
            </a>{' '}
            <a className="btn btn-danger" rel="nofollow" onClick={() => this.logincookie()}>
              Ik snap het
            </a>
          </div>
        )}
      </div>
    );
  }

  getbar() {
    let search = this.getUrlParams();
    return search.get('bar') || '';
  }
  getUrlParams() {
    if (!this.props.location.search) return new URLSearchParams();
    return new URLSearchParams(this.props.location.search);
  }
}



function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setMenu, setSystemText, setProp }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(withRouter(NavBar))
);
