import * as React from 'react';
import { SingleDatePicker } from 'react-dates';
import { getAirports, GetAirportWithCity } from '../store/flight';
import { connect } from 'react-redux';
import { AutoComplete } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import { getControlText, getLanguageID, getLocale } from '../shared/functions';
import { Link } from 'react-router-dom';
import Border_soft from './border_soft';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setProp, setSystemText } from '../../../modules/general';
import Swal from 'sweetalert2';

class SearchFlight2 extends React.Component {
  constructor(props) {
    super(props);

    const errors = {};
    const values = {};

    if (getLanguageID().toString() === '5') {
      require('moment/locale/tr');
    } else if (getLanguageID().toString() === '1') {
      moment().locale('en-gb');

      require('moment/locale/en-gb');
    } else if (getLanguageID().toString() === '2') {
      require('moment/locale/de');
    } else if (getLanguageID().toString() === '3') {
      require('moment/locale/nl');
    }

    let startDate = this.props.FlightDate ? moment(this.props.FlightDate) : moment().add(1, "days");
    let endDate = this.props.RetFlightDate ? moment(this.props.RetFlightDate) : moment().add(2, "days");

    var depID = this.props.FromAirport ? this.props.FromAirport : 'AMS';
    var arrID = this.props.ToAirport ? this.props.ToAirport : 'AYT';

    let getAirportCode = () => {
      let isLastMinute = typeof window !== "undefined" ? true : false;
      isLastMinute = isLastMinute && typeof window !== "undefined" ? window.location.pathname.includes("last-minute") : false;
      if(isLastMinute) {
        switch (window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]) {
          case "dalaman":
            arrID = "DLM";
            break;
          case "izmir":
            arrID = "ADB";
            break;
          case "antalya":
            arrID = "AYT";
            break;
          case "alanya":
            arrID = "GZP";
            break;
          case "bodrum":
            arrID = "BJV";
            break;
          case "kayseri":
            arrID = "ASR";
            break;
          case "konya":
            arrID = "KYA";
            break;
          case "ankara":
            arrID = "ESB";
            break;
          case "istanbul":
            arrID = "IST";
            break;
        }
      }
    }
    getAirportCode();

    var oneway = this.props.oneway && this.props.oneway == '1' ? false : true;
    var adultCount = this.props.Adult ? parseInt(this.props.Adult) : 1;
    var childCount = this.props.Child ? parseInt(this.props.Child) : 0;
    var babyCount = this.props.Baby ? parseInt(this.props.Baby) : 0;

    var _startDate = startDate;
    var _oneWay = oneway;
    var _direct = true;
    
    if(typeof window !== "undefined"){
      _startDate = window.location.pathname.includes("last-minute") ? moment() : startDate;
      _oneWay = window.location.pathname.includes("last-minute") ? false : oneway;
      _direct = window.location.pathname.includes("last-minute") ? false : true;
    }

    this.state = {
      startDate: _startDate,
      endDate: endDate,
      focusedInput: '',
      focused: false,
      dataSourceArrival: [],
      dataSourceDeparture: [],
      departureID: depID,
      arrivalID:  arrID,
      oneway: _oneWay,
      rentacar: this.props.rentacar && this.props.rentacar == '1' ? true : false,
      passenger: true,
      adult: adultCount,
      child: childCount,
      baby: babyCount,
      remember: false,
      Passengercount: adultCount + childCount + babyCount > 0 ? adultCount + childCount + babyCount + ' passagiers' : '1 passagiers',
      Direct: _direct,
      errors,
      values,
      valarri: '',
      valdep: '',
      valdate: '',
      departure: '',
      departureSelected: false,
      arrivalSelected: false,
      redirectUrl: '/FlightSearch?',
      redirectPath: {},
      arrivalCountry: 'turkije',
      departureCity: this.props.FromAirport,
      arrivalCity: this.props.ToAirport,
      enteresan: this.props.envelope
    };

    if(typeof window !== "undefined" && window.location.pathname.includes("last-minute")){
      this.setState({startDate: moment(), oneway: false, Direct: false});
    }

    this.handleSearchDeparture = this.handleSearchDeparture.bind(this);
    this.handleSearchArrival = this.handleSearchArrival.bind(this);
    this.onSelectArrival = this.onSelectArrival.bind(this);
    this.onSelectDeparture = this.onSelectDeparture.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onDepartureFocus = this.onDepartureFocus.bind(this);
    this.onReturnFocus = this.onReturnFocus.bind(this);
    this.onDepartureBlur = this.onDepartureBlur.bind(this);
    this.onReturnBlur = this.onReturnBlur.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.checkSubmit = this.checkSubmit.bind(this);

    this.getRedirectObjectdetailv2 = this.getRedirectObjectdetailv2.bind(this);
  }

  getLocalState() {
    let localState = {};
    if (this.props && this.props.location && this.props.location.state) {
      localState = this.props.location.state;
    } else {
      let cookieParamsState = Cookies.get('searchParams');
      if (cookieParamsState) {
        localState = JSON.parse(cookieParamsState);
      }
    }
    return localState;
  }

  componentDidMount() {
    var OldRememberSearchCheck = Cookies.get('RememberSearch');
    if (OldRememberSearchCheck) {
      let newArr = [];
      var arr = OldRememberSearchCheck ? JSON.parse(OldRememberSearchCheck) : [];

      if (arr.length > 0) {
        arr.map((row, index) => {
          let formrecord2 = {
            s: row.startDate,
            e: row.endDate,
            f: row.departureID,
            t: row.arrivalID,
            o: row.oneway,
            a: row.adult,
            c: row.child,
            b: row.baby,
            n: row.OnlyDirect
          };
          newArr.push(formrecord2);
        });

        Cookies.set('rs', newArr);
        Cookies.remove('RememberSearch');
      }
    }

    let _that = this;

    let localState = this.getLocalState();

    getAirports('').then(val => {
      val = {
        data: {
          d: val.data.map(airport => {
            return {
              City: airport.city,
              Country: airport.country,
              FullName: airport.name,
              ID: airport.code,
              IataFullName: airport.code + ' - ' + airport.city,
              Name: airport.name,
              __type: 'AirportDataClass'
            };
          })
        }
      };
      this.props.setProp(val.data.d, 'airports');
      var loadDefaults = true;
      if (this.props && this.props.location && this.props.location.pathname) {
        let paths = ([] = this.props.location.pathname.split('/'));
        if (paths.length > 2) {
          let currentPage = paths[1].toString().toLowerCase();
          if (currentPage == 'goedkope-vliegtickets' || currentPage == 'last-minute' || currentPage == 'vliegveld') {
            let page = paths[paths.length - 1];
            GetAirportWithCity(page, getLanguageID().toString()).then(val2 => {
              if (currentPage == 'vliegveld') {
                let page = paths[paths.length - 2];
                GetAirportWithCity(page, getLanguageID().toString()).then(val3 => {
                  this.setState({
                    arrivalID: val2.data.d[0].IATA,
                    placeHolderArr: val2.data.d[0].Name,
                    placeHolderDep: val3.data.d[0].Name,
                    departureID: val3.data.d[0].IATA,
                    isSearch: true,
                    Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false
                  });
                });
              } else {
                let sonuc = _.find(val.data.d, function(o) {
                  return o.ID == 'AMS';
                });
                if (val2.data.d && val2.data.d.length > 0) {
                  this.setState({
                    arrivalID: val2.data.d[0].IATA,
                    placeHolderArr: val2.data.d[0].Name,
                    placeHolderDep: sonuc.Name,
                    departureID: sonuc.ID,
                    isSearch: true,
                    Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false
                  });
                }
              }
              loadDefaults = false;
            });
          } else if (currentPage == 'vliegtickets') {
            if (paths.length == 4 && paths[3].indexOf('-') > -1 && localState) {
              let sonucArr = _.find(val.data.d, function(o) {
                return o.ID == localState.ToAirport;
              });
              let sonucDep = _.find(val.data.d, function(o) {
                return o.ID == localState.FromAirport;
              });

              this.setState({
                arrivalID: _that.props.ToAirport,
                placeHolderArr: sonucArr ? sonucArr.Name : '',
                placeHolderDep: sonucDep ? sonucDep.Name : '',
                departureID: _that.props.FromAirport,
                isSearch: true,
                Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false
              });
              loadDefaults = false;
            } else {
              if (currentPage == 'vliegtickets' && (!_that.props.ToAirport && !_that.props.FromAirport)) {
                let page = paths[paths.length - 1];
                GetAirportWithCity(page, getLanguageID().toString()).then(val2 => {
                  if (val2.data.d && val2.data.d.length > 0) {
                    let sonucArr = _.find(val.data.d, function(o) {
                      return o.ID == val2.data.d[0].IATA;
                    });

                    this.setState({
                      arrivalID: val2.data.d[0].IATA,
                      placeHolderArr: val2.data.d[0].Name,
                      placeHolderDep: 'Amsterdam',
                      isSearch: true,
                      departureID: sonucArr.ID,
                      Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false,
                      arrivalCity: sonucArr.City
                    });

                    loadDefaults = false;
                  }
                });
              } else if (!_that.props.ToAirport || !_that.props.FromAirport) {
                let page = paths[paths.length - 1];

                GetAirportWithCity(page, getLanguageID().toString()).then(val2 => {
                  if (val2.data.d && val2.data.d.length > 0) {
                    let sonucArr = _.find(val.data.d, function(o) {
                      return o.ID == val2.data.d[0].IATA;
                    });

                    this.setState({
                      arrivalID: val2.data.d[0].IATA,
                      placeHolderArr: val2.data.d[0].Name,
                      placeHolderDep: 'Amsterdam',
                      departureID: sonucArr.ID,
                      isSearch: true,
                      Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false,
                      arrivalCity: sonucArr.City
                    });

                    loadDefaults = false;
                  }
                });
              } else {
                let sonucArr = _.find(val.data.d, function(o) {
                  return o.ID == _that.props.ToAirport;
                });
                let sonucDep = _.find(val.data.d, function(o) {
                  return o.ID == _that.props.FromAirport;
                });

                this.setState({
                  arrivalID: _that.props.ToAirport,
                  placeHolderArr: sonucArr ? sonucArr.Name : '',
                  placeHolderDep: sonucDep ? sonucDep.Name : '',
                  departureID: _that.props.FromAirport,
                  isSearch: true,
                  Direct: this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false
                });
              }
            }
          }
        }
      }

      if (this.props && this.props.general) {
        if (loadDefaults) {
          var onlyDirect = true;

          onlyDirect = this.props && this.props.OnlyDirect && this.props.OnlyDirect == '1' ? true : false;

          let sonuc = _.find(val.data.d, function(o) {
            return o.ID == _that.state.departureID;
          });
          let sonucret = _.find(val.data.d, function(o) {
            return o.ID == _that.state.arrivalID;
          });

          this.setState(
            {
              departureCity: sonuc.City,
              arrivalCity: sonucret.City,
              arrivalCountry: sonucret.Country
            },
            () => this.checkSubmit(false)
          );

          this.setState({
            departure: sonuc.Name,
            arrival: sonucret.Name,
            airports: val.data.d,
            Direct: onlyDirect
          });

          this.setState(
            {
              placeHolderDep: this.props.FromAirport ? sonuc.Name : [] ? getControlText('59', []).Text : '',
              placeHolderArr: this.props.ToAirport ? sonucret.Name : [] ? getControlText('6', []).Text : ''
            },

            () => {
              this.checkSubmit(false);

              if (this.props.tagpush) {
                let tagManagerArgs = {
                  dataLayer: {
                    event: 'FlightSearch',
                    from: this.state.placeHolderDep,
                    to: this.state.placeHolderArr,
                    adult: this.state.adult,
                    child: this.state.child,
                    baby: this.state.baby,
                    startDate: moment(this.state.startDate).format('DD-MM-YYYY'),
                    endDate: moment(this.state.endDate).format('DD-MM-YYYY'),
                    searchtype: this.state.oneway ? 'ROUNDTRIP' : 'ONEWAY'
                  },
                  dataLayerName: 'dataLayer'
                };

                TagManager.dataLayer(tagManagerArgs);
              }
            }
          );
        }
      }
    });
  }

  toggleroundtrip() {
    this.setPropsToState().then(() => {
      this.setState({
        rentacar: false
      });

      if (this.state.oneway === false) {
        let depDate = this.state.startDate;

        if (!moment(depDate).isValid()) {
          depDate = moment();
        }
        this.setState(
          {
            endDate: moment(depDate).add(1, 'days'),
            oneway: true
          },
          () => this.checkSubmit(false)
        );
      }
    });
  }

  toggleoneway() {
    this.setPropsToState().then(() => {
      this.setState({
        rentacar: false
      });
      if (this.state.oneway === true) {
        this.setState({ oneway: false }, () => {
          this.checkSubmit(false);
        });
      } else {
        this.setState(
          {
            endDate: ''
          },
          () => this.checkSubmit(false)
        );
      }
    });
  }

  toggleDirect() {
    this.setPropsToState().then(() => {
      if (this.state.Direct === false) {
        this.setState(
          {
            Direct: true
          },
          () => this.checkSubmit(true)
        );
      } else this.checkSubmit(true);
    });
  }

  toggleAlternate() {
    this.setPropsToState().then(() => {
      if (this.state.Direct === true) {
        this.setState(
          {
            Direct: false
          },
          () => this.checkSubmit(true)
        );
      } else this.checkSubmit(true);
    });
  }

  togglePassenger() {
    this.setPropsToState().then(() => {
      this.setState(
        {
          passenger: !this.state.passenger
        },
        () => this.checkSubmit(false)
      );
    });
  }

  toggleRemember(durum) {
    this.setState({
      remember: durum
    });
  }

  getTotalPassengerCount(){
    return this.state.adult + this.state.child;
  }

  isPassengerMaxed(){
    let result = this.getTotalPassengerCount() >= 9;
    return result;
  }

  Incrementadult() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ adult: this.isPassengerMaxed() ? this.state.adult : this.state.adult + 1 }, () => this.checkSubmit(false));
  }
  Decreaseadult() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ adult: this.state.adult < 2 ? 1 : this.state.adult - 1, baby: 0 }, () => this.checkSubmit(false));
  }

  Incrementchild() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ child: this.isPassengerMaxed() ? this.state.child : this.state.child + 1 }, () => this.checkSubmit(false));
  }
  Decreasechild() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ child: this.state.child < 1 ? 0 : this.state.child - 1 }, () => this.checkSubmit(false));
  }

  Incrementbaby() {
    let adultCount = this.state.adult,
      babyCount = this.state.baby;

    if (adultCount <= babyCount) {
      let warningText = '';

      switch (getLocale()) {
        case 'en-GB':
          warningText = 'The number of babies cannot exceed the number of adults';
          break;
        case 'de-DE':
          warningText = 'Die Anzahl der Babys darf die Anzahl der Erwachsenen nicht überschreiten';
          break;
        case 'nl-NL':
          warningText = "Het aantal baby's mag niet groter zijn dan het aantal volwassenen";
          break;
        case 'tr-TR':
          warningText = 'Bebek sayısı yetişkin sayısından fazla olamaz';
          break;
        default:
          warningText = "Het aantal baby's mag niet groter zijn dan het aantal volwassenen";
          break;
      }

      Swal.fire({
        title: 'Error!',
        text: warningText,
        icon: 'error',
        confirmButtonText: 'Continue'
      });
    } else {
      this.props.setProp({}, 'selectedHistory');
      this.setState({ baby: babyCount + 1 }, () => this.checkSubmit(false));
    }
  }
  Decreasebaby() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ baby: this.state.baby < 1 ? 0 : this.state.baby - 1 }, () => this.checkSubmit(false));
  }

  PassengerCount() {
    this.props.setProp({}, 'selectedHistory');
    this.setState({ Passengercount: this.state.adult + this.state.child + this.state.baby + ' passagiers' }, () => this.checkSubmit(false));
    this.togglePassenger();
  }

  getRedirectObjectdetailv2(url, fromID, toID, startdate, enddate, oneway, adult, child, baby, direct) {
    let redTo = {
      pathname: url,
      state: {
        SearchType: '1',
        FlightDate: moment(startdate),
        RetFlightDate: moment(enddate),
        FromAirport: fromID,
        ToAirport: toID,
        oneway: oneway === 1 || oneway === true || oneway === '1' ? 1 : 0,
        Adult: adult,
        Child: child,
        Baby: baby,
        OnlyDirect: direct ? 1 : 0,
        From: '',
        To: ''
      }
    };

    this.props.setProp(redTo, 'selectedHistory').then(() => {
      this.toggleRemember(false);
      this.setPropsToState();
    });
  }

  handleSearchArrival = value => {
    let departureCity =
      this.state.departureCity === 'Istanbul Sabiha'
        ? 'Istanbul'
        : this.state.departureCity === 'Istanbul Airport'
          ? 'Istanbul'
          : this.state.departureCity;

    if (!value) {
      this.setState({
        arrival: '',
        arrivalCity: '',
        dataSourceArrival: []
      });
    } else {
      let arrival = !value ? [] : this.getData(value);
      let cityArray = arrival.length > 0 ? arrival[0].text.split(' - ') : [];
      let citySuffix = cityArray[1];
      let arrivalCity = citySuffix === 'Istanbul Airport' ? 'Istanbul' : citySuffix === 'Istanbul Sabiha' ? 'Istanbul' : citySuffix;
      if (departureCity !== arrivalCity) {
        this.setState({
          dataSourceArrival: arrival
        });
      }
    }
  };

  handleSearchDeparture = value => {
    let arrivalCity =
      this.state.arrivalCity === 'Istanbul Sabiha' ? 'Istanbul' : this.state.arrivalCity === 'Istanbul Airport' ? 'Istanbul' : this.state.arrivalCity;

    if (!value) {
      this.setState({
        departure: '',
        departureCity: '',
        dataSourceDeparture: []
      });
    } else {
      let departure = !value ? [] : this.getData(value);
      let cityArray = departure.length > 0 ? departure[0].text.split(' - ') : [];
      let citySuffix = cityArray[1];
      let departureCity = citySuffix === 'Istanbul Airport' ? 'Istanbul' : citySuffix === 'Istanbul Sabiha' ? 'Istanbul' : citySuffix;

      this.setState({
          dataSourceDeparture: departure
        });
    }
  };

  onSelectArrival(value) {
    this.setPropsToState().then(() => {
      let sonuc = _.find(this.state.dataSourceArrival, function(o) {
        return o.value == value;
      });
      let sonucArr = _.find(this.state.airports, function(o) {
        return o.ID == value;
      });

      this.setState(
        {
          arrivalID: value,
          arrival: sonuc.text,
          arrivalSelected: false,
          arrivalCity: sonucArr.City,
          arrivalCountry: sonucArr.Country
        },
        () => this.checkSubmit(false)
      );
    });
  }

  onSelectDeparture(value) {
    this.setPropsToState().then(() => {
      let sonuc = _.find(this.state.dataSourceDeparture, function(o) {
        return o.value == value;
      });

      let sonucDep = _.find(this.state.airports, function(o) {
        return o.ID == value;
      });

      this.setState(
        {
          departureID: value,
          departure: sonuc.text,
          departureSelected: false,
          departureCity: sonucDep.City,
          departureCountry: sonucDep.Country
        },
        () => this.checkSubmit(false)
      );
    });
  }

  onDepartureFocus() {
    this.setPropsToState().then(() => {
      var RememberSearchCheck = Cookies.get('rs');

      if (RememberSearchCheck) {
        var arr = RememberSearchCheck ? JSON.parse(RememberSearchCheck) : [];

        if (arr.length > 0) {
          arr
            .filter(function(valueOf) {
              return moment(arr.s) < moment(valueOf.s);
            })
            .reverse()
            .forEach(o => {
              if (o.f === undefined) {
                Cookies.remove('rs');
              }
            });
        }
      }

      this.setState({
        placeHolderDep: '',
        remember: true
      });
    });
  }

  onReturnFocus() {
    this.setState({
      placeHolderArr: ''
    });
  }

  onDepartureBlur() {
    this.setPropsToState().then(() => {
      if (this.state.placeHolderDep === '') {
        this.setState({
          placeHolderDep: this.state.isSearch ? this.state.departure : getControlText('59', []).Text,
          dataSourceDeparture: []
        });
      }
    });
  }

  onReturnBlur() {
    this.setPropsToState().then(() => {
      this.setState({
        placeHolderArr: this.state.isSearch ? this.state.arrival : getControlText('6', []).Text,
        dataSourceArrival: []
      });
    });
  }

  haveErrors(errors) {
    let haveError = false;
    Object.keys(errors).map(key => {
      if (errors[key].length > 0) {
        haveError = true;
      }
    });
    return haveError;
  }

  /**
   * Handles form submission
   * @param event
   */

  handleSubmit(e) {
    if (!this.validateForm()) {
      e.preventDefault();
    }
  }

  setPropsToState() {
    return new Promise(resolve => {
      if (this.props.general.selectedHistory != undefined && this.props.general.selectedHistory != {} && this.props.general.selectedHistory.state) {
        let _state = this.props.general.selectedHistory.state;

        let depID = _state.FromAirport;
        let arrID = _state.ToAirport;

        let sonuc = _.find(this.props.general.airports, function(o) {
          return o.ID == depID;
        });
        let sonucret = _.find(this.props.general.airports, function(o) {
          return o.ID == arrID;
        });

        let placeHolderDep = sonuc.Name;
        let placeHolderArr = sonucret.Name;
        let _oneway = _state.oneway === 1 ? false : true;
        let _direct = _state.OnlyDirect === 1 ? true : false;

        this.setState(
          {
            departureID: _state.FromAirport,
            arrivalID: _state.ToAirport,
            startDate: _state.FlightDate,
            endDate: _state.RetFlightDate,
            placeHolderDep: placeHolderDep,
            placeHolderArr: placeHolderArr,
            departureCountry: sonuc.Country,
            departureCity: sonuc.City,
            arrivalCountry: sonucret.Country,
            arrivalCity: sonucret.City,
            Direct: _direct,
            oneway: _oneway
          },
          () => {
            this.props.setProp({}, 'selectedHistory');
            resolve('ok');
          }
        );
      } else resolve('not');
    });
  }

  checkSubmit(submitting) {
    this.setPropsToState().then(val => {
      let redTo = {};
      let valFormCheck = this.validateForm();

      if (valFormCheck) {
        let _state = this.state;

        let redirectPath = '';
        if (_state.arrivalCountry && _state.arrivalCity) {
          redirectPath =
            '/vliegtickets/' + _state.departureCity.toLowerCase().replace(' ', '') + '/' + _state.arrivalCity.toLowerCase().replace(' ', '');
        }

        let airs = [];
        airs = this.props && this.props.general && this.props.general.airports;

        let fromAir = [];
        let toAir = [];
        let depAir = '',
          arrAir = '',
          depCity = '',
          arrCity = '';

        if (airs && airs.length > 0) {
          fromAir = _.find(this.props.general.airports, function(o) {
            return o.ID == _state.departureID;
          });
          toAir = _.find(this.props.general.airports, function(o) {
            return o.ID == _state.arrivalID;
          });
          depAir = fromAir.Id;
          arrAir = toAir.Id;
          depCity = fromAir.City;
          arrCity = toAir.City;
        }

        var _onlyDirect = _state.Direct ? 1 : 0;

        if(typeof window !== "undefined"){
          _onlyDirect = window.location.pathname.includes("last-minute") ? 0 : _state.Direct ? 1 : 0;
        }

        redTo = {
          pathname: redirectPath,
          state: {
            SearchType: '1',
            FlightDate: moment(_state.startDate).format('YYYY-MM-DD'),
            RetFlightDate: moment(_state.endDate).format('YYYY-MM-DD'),
            FromAirport: _state.departureID ? _state.departureID : 0,
            ToAirport: _state.arrivalID ? _state.arrivalID : 0,
            oneway: _state.oneway ? '0' : '1',
            Adult: _state.adult,
            Child: _state.child,
            Baby: _state.baby ? _state.baby : 0,
            OnlyDirect: _onlyDirect,
            From: _state.valarr ? _state.valarr : '',
            To: _state.valdep ? _state.valdep : '',
            departureCity: _state.valarr ? _state.valarr : '',
            arrivalCity: _state.valdep ? _state.valdep : '',
            depAir: depAir,
            arrAir: arrAir,
            depCity: depCity,
            arrCity: arrCity,
            isSearch: true
          }
        };

        this.setState({ redirectPath: redTo }, () => {
          if (submitting) {
            let dataPush = {
              pathname: redirectPath,
              search: '',
              state: { ...redTo.state }
            };

            Cookies.set('searchParams', redTo.state);
            this.props.history.push(dataPush);
            if(typeof window !== "undefined")
              window.location.href = redirectPath;
          }
        });
      }
    });
  }

  /**
   * Executes the validation rules for all the fields on the form and sets the error state
   * @returns {boolean} - Whether the form is valid or not
   */
  validateForm() {
    let durum = true;
    if (this.state.startDate === '') {
      durum = false;
    }
    if (this.state.endDate === '' && this.state.oneway) {
      durum = false;
    }
    if (this.state.departureID === 0) {
      this.setState({
        departureID: 'AMS'
      });
    }
    if (this.state.arrivalID === 0) {
      this.setState({
        arrivalID: 'AYT'
      });
    }

    if (!durum) {
      this.setState({
        valdate: 'border-red'
      });
    }

    // TODO - validate form
    return durum;
  }

  /**
   * Submits the form to the http api
   * @returns {boolean} - Whether the form submission was successful or not
   */

  ////

  //Test comment

  getData(search) {
    let result = [];
    if (this.props && this.props.general && this.props.general.airports) {
      if (search.length == 3) {
        let orj = [];
        this.setState({
          remember: false
        });

        orj = _.filter(this.props.general.airports, function(o) {
          //denemezz
          // return o.IataFullName.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
          return (
            o.IataFullName.substring(0, search.length).toLowerCase() === search.toLowerCase() ||
            o.IataFullName.substring(0, search.length).toLocaleLowerCase() === search.toLocaleLowerCase()
          );
        });

        orj.forEach(function(airdata) {
          result.push({ key: airdata.ID, text: airdata.IataFullName, value: airdata.ID });
        });
      } else if (search.length >= 4) {
        let orj = [];
        this.setState({
          remember: false
        });

        orj = _.filter(this.props.general.airports, function(o) {
          return o.FullName.toLowerCase().includes(search.toLowerCase()) || o.FullName.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        });

        orj.forEach(function(airdata) {
          result.push({ key: airdata.ID, text: airdata.IataFullName, value: airdata.ID });
        });
      } else result = [];
    }

    return result;
  }

  checkReturn() {
    let depDate = this.state.startDate;
    if (!moment(depDate).isValid()) {
      depDate = moment();
    }

    let retDate = this.state.endDate;
    let diff = moment(retDate).diff(depDate, 'day');

    if (diff < 0) {
      this.setState({ endDate: this.state.startDate });
    } else this.setState({ endDate: moment(depDate).add(1, 'days') });
  }

  change_airport() {
    let newArrival = this.state.departureID;
    let newDeparture = this.state.arrivalID;

    let sonucDep = _.find(this.state.airports, function(o) {
      return o.ID == newDeparture;
    });
    let sonucArr = _.find(this.state.airports, function(o) {
      return o.ID == newArrival;
    });

    this.setState({
      placeHolderDep: sonucDep.Name,
      placeHolderArr: sonucArr.Name,
      departureCountry: sonucDep.Country,
      departureCity: sonucDep.City,
      arrivalCountry: sonucArr.Country,
      arrivalCity: sonucArr.City,
      departureID: sonucDep.ID,
      Departure: sonucDep.Name,
      departureSelected: false,
      arrivalSelected: false,
      arrivalID: sonucArr.ID,
      Arrival: sonucArr.Name,
      dataSourceDeparture: [],
      dataSourceArrival: []
    });
  }

  handleDateChange = (date, type) => {
    const maxEndDate = new Date();
    const maxStartDate = new Date();
    maxEndDate.setDate(maxEndDate.getDate() + 365);
    maxStartDate.setDate(maxStartDate.getDate() + 364);
    if (type === 'startDate') {
      if (date > maxStartDate) {
        date = new Date(maxStartDate);
        date.setDate(date.getDate() - 1);
      }
      this.setState({ startDate: date == null ? '' : moment(date) }, () => {
        this.setPropsToState().then(() => {
          this.checkReturn();
          this.props.setProp({}, 'selectedHistory');
          this.setState({ focused2: true }, () => this.checkSubmit(false));
        });
      });
    } else if (type === 'endDate') {
      if (date > maxEndDate) {
        date = new Date(maxEndDate);
        date.setDate(date.getDate() - 1);
      }
      this.setState({ endDate: date == null ? '' : moment(date) }, () => {
        this.setPropsToState().then(() => {
          this.checkSubmit(false);
        });
      });
    }
  };

  render() {
    var depID = this.props.FromAirport ? this.props.FromAirport : 'AMS';
    var arrID = this.props.ToAirport ? this.props.ToAirport : 'AYT';

    var startDate = this.state.startDate;

    var endDate = this.state.endDate;
    var oneway = this.state.oneway;

    var placeHolderDep = this.state.placeHolderDep;
    var placeHolderArr = this.state.placeHolderArr;

    let Direct = this.state.Direct;

    if(typeof window !== "undefined" && window.location.pathname.includes("last-minute")){
      oneway = false;
      Direct = false;
      startDate = moment();
    }
    if (this.props && this.props.general && this.props.general.selectedHistory && this.props.general.airports) {
      if (this.props.general.selectedHistory != undefined && this.props.general.selectedHistory != {} && this.props.general.selectedHistory.state) {
        const _state = this.props.general.selectedHistory.state;
        depID = _state.FromAirport;
        arrID = _state.ToAirport;

        let sonuc = _.find(this.props.general.airports, function(o) {
          return o.ID == depID;
        });
        let sonucret = _.find(this.props.general.airports, function(o) {
          return o.ID == arrID;
        });
        placeHolderDep = sonuc.Name;
        placeHolderArr = sonucret.Name;

        startDate = _state.FlightDate;
        endDate = _state.RetFlightDate;
        Direct = _state.OnlyDirect;
        oneway = _state.oneway;
        if (oneway === 1 || oneway === '1') oneway = false;
        if (oneway === 0 || oneway === '0') oneway = true;
      }
    }

    let datePhrases = {
      keyboardNavigationInstructions: ''
    };

    let roundTripText = '';
    let vertrekText = '';
    let Departure = '';
    let Arrival = '';
    let btnSearch = '';
    let ltrAdult = '';
    let ltrChild = '';
    let ltrBaby = '';
    let ltrDeparture = '';
    let ltrReturn = '';
    let ltrNeedAssistance = '';
    let radioSearch = '';
    let radioSearchDirect = '';
    let okey = '';
    let setrecent = '';
    let rentcar = '';

    let passengertext = '';

    roundTripText = getControlText('24', []).Text;
    rentcar = getControlText('330', []).Text;
    vertrekText = getControlText('56', []).Text;
    passengertext = getControlText('998', []).Text;
    okey = getControlText('928', []).Text;

    btnSearch = getControlText('134', []).Text;
    ltrAdult = getControlText('61', []).Text;
    ltrChild = getControlText('62', []).Text;
    ltrBaby = getControlText('304', []).Text;
    Departure = placeHolderDep;
    Arrival = placeHolderArr;
    ltrDeparture = getControlText('937', []).Text;
    ltrReturn = getControlText('938', []).Text;
    ltrNeedAssistance = getControlText('717', []).Text;
    radioSearch = getControlText('766', []).Text;
    radioSearchDirect = getControlText('765', []).Text;
    setrecent = getControlText('999', []).Text;

    var RememberSearch1 = Cookies.get('rs');

    var dizim = [];

    if (this.props && this.props.general && this.props.general.airports) {
      if (RememberSearch1) {
        var arr = RememberSearch1 ? JSON.parse(RememberSearch1) : [];
        if (arr.length > 0) {
          let filteredArr = arr.filter(function(valueOf) {
            return moment(valueOf.s) > moment();
          });

          filteredArr.reverse().forEach(o => {
            let sonucDep = _.find(this.props.general.airports, function(p) {
              return p.ID == o.f;
            });
            let sonucArr = _.find(this.props.general.airports, function(p) {
              return p.ID == o.t;
            });

            let urlsend = '/vliegtickets/' + sonucDep.City.toLowerCase().replace(' ', '') + '/' + sonucArr.City.toLowerCase().replace(' ', '');
            dizim.push(
              <div className="remember-item" onClick={() => this.getRedirectObjectdetailv2(urlsend, o.f, o.t, o.s, o.e, o.o, o.a, o.c, o.b, o.n)}>
                <div className="row _pl-10 _pb-5">
                  <div className="col-xs-12 blfd_content ">
                    <div className="text-center ">
                      {moment(o.s).format('ddd DD MMM YYYY')} {o.e != 'Invalid date' ? '- ' + moment(o.e).format('ddd DD MMM YYYY') : ''} ,{' '}
                      {parseInt(o.a) + parseInt(o.c) + parseInt(o.b)} {passengertext}
                    </div>
                  </div>
                </div>
                <div className="row  _pl-10">
                  <div className="col-xs-12 ">
                    <div className="_pb-5">
                      <i className="fa fa-plane " /> <span className=" _pl-5">{sonucDep.Name}</span>
                    </div>
                    <div className="soft-color ">
                      <i className="fa fa-plane  return_icon" /> <span className=" _pl-5">{sonucArr.Name}</span>
                    </div>
                  </div>
                </div>
                <hr className="ozel_row" />
              </div>
            );
          });
        }
      }
    }

    const { dataSourceArrival } = this.state;
    const { dataSourceDeparture } = this.state;
    const { submitSuccess, errors } = this.state;
    return (
      <div>
        <div className="theme-search-area theme-search-area-stacked theme-search-area-white">
          <div className="row row-height-60 btnsearchrow" data-gutter="none">
            <div className="col-md-2 col-sm-4 col-xs-4  ">
              <a
                className={
                  this.state.rentacar
                    ? 'btn btn-primary-inverse-active btn-block theme-coming-soon-form-btn'
                    : oneway === true
                      ? 'btn btn-primary-inverse btn-block theme-coming-soon-form-btn'
                      : 'btn btn-primary-inverse-active btn-block theme-coming-soon-form-btn'
                }
                onClick={this.toggleroundtrip.bind(this)}>
                <div className="round-trip-ico" />
                {roundTripText}{' '}
              </a>
            </div>

            <div className="col-md-2 col-sm-4 col-xs-4 ">
              <a
                className={
                  this.state.rentacar
                    ? 'btn btn-primary-inverse-active btn-block theme-coming-soon-form-btn'
                    : oneway === true
                      ? 'btn btn-primary-inverse-active btn-block theme-coming-soon-form-btn '
                      : 'btn btn-primary-inverse btn-block theme-coming-soon-form-btn'
                }
                onClick={this.toggleoneway.bind(this)}>
                {' '}
                <i className="fa fa-plane" /> {vertrekText}{' '}
              </a>
            </div>
            <div className="col-md-2 col-sm-4 col-xs-4 ">
              <a
                style={{fontSize:"17px"}}
                className={
                  this.state.rentacar
                    ? 'btn btn-primary-inverse btn-block theme-coming-soon-form-btn'
                    : 'btn btn-primary-inverse-active btn-block theme-coming-soon-form-btn'
                }
                href="/rentacar">
                {' '}
                <i className="fa fa-car" /> {rentcar}{' '}
              </a>
            </div>
          </div>
          <div className="theme-search-area-form" id="hero-search-form">
            <div className="row">
              <div className="col-md-12 ">
                <div className="row">
                  <div className="col-md-6 ">
                    <div className="row">
                      <div className="col-md-5 ">
                        <div className="theme-search-area-section first theme-search-area-section-curved ">
                          <div className={this.state.valdep}>
                            <div className="theme-search-area-section-inner ">
                              <i className="theme-search-area-section-icon fa fa-plane" />
                              <AutoComplete
                                dataSource={dataSourceDeparture}
                                onSelect={this.onSelectDeparture}
                                onSearch={this.handleSearchDeparture}
                                placeholder={Departure}
                                onFocus={this.onDepartureFocus}
                                onBlur={this.onDepartureBlur}
                              />
                              {this.state.remember &&
                                dizim.length > 0 && (
                                  <div className="quantity-selector-box-rem  " style={{ width: '320px' }}>
                                    <div className="text-right closedrem">
                                      <a className="btn btn-bg-default whitebold " onClick={() => this.toggleRemember(false)}>
                                        X
                                      </a>
                                    </div>
                                    <div className="banner-baslik_rem">
                                      <p className="quantity-selector-title-rem">{setrecent}</p>
                                    </div>
                                    <div className="remembersearch barstyle">{dizim}</div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-2 ">
                        <div className="theme-search-area-section  theme-search-area-section-curved ">
                          <button
                            className="theme-search-area-submit _mt-0 theme-search-area-submit-glow theme-search-area-submit-curved"
                            onClick={this.change_airport.bind(this)}
                            aria-label="change">
                            <i className="theme-search-area-submit-icon fa fa-exchange " />
                            <span className="_desk-h">
                              {' '}
                              <i className="font-size-42 fa fa-sort" />
                            </span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-5 ">
                        <div className="theme-search-area-section theme-search-area-section-curved">
                          <div className={this.state.valarri}>
                            <div className="theme-search-area-section-inner">
                              <i className="theme-search-area-section-icon fa fa-plane icon-ters-cevir" />

                              <AutoComplete
                                dataSource={dataSourceArrival}
                                onSelect={this.onSelectArrival}
                                placeholder={Arrival}
                                onSearch={this.handleSearchArrival}
                                onFocus={this.onReturnFocus}
                                onBlur={this.onReturnBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {oneway && (
                    <div className="col-md-6 ">
                      <div className="row">
                        <div className=" col-md-6">
                          <div className={this.state.valdate + '  _bg-white _br-5 theme-search-area-section first theme-search-area-section-curved '}>
                            <SingleDatePicker
                              orientation="horizontal"
                              numberOfMonths={1}
                              date={startDate} // momentPropTypes.momentObj or null
                              onDateChange={startDate => this.handleDateChange(startDate, 'startDate')} // PropTypes.func.isRequired
                              focused={this.state.focused} // PropTypes.bool
                              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                              id="hidDepartureFill" // PropTypes.string.isRequired,
                              placeholder={ltrDeparture}
                              displayFormat={() => 'DD-MM-YYYY'}
                              readOnly={true}
                              showClearDate
                              block
                              isOutsideRange={day => day.isBefore(moment().startOf('day'))}
                              hideKeyboardShortcutsPanel
                              phrases={datePhrases}
                            />
                          </div>
                        </div>
                        <div className=" col-md-6">
                          <div className={this.state.valdate + '  _bg-white _br-5 theme-search-area-section first theme-search-area-section-curved '}>
                            {oneway && (
                              <SingleDatePicker
                                orientation="horizontal"
                                numberOfMonths={1}
                                date={endDate}
                                onDateChange={endDate => this.handleDateChange(endDate, 'endDate')}
                                focused={this.state.focused2}
                                onFocusChange={({ focused }) => this.setState({ focused2: focused })}
                                id="hidDepartureFill2"
                                placeholder={ltrReturn}
                                displayFormat={() => 'DD-MM-YYYY'}
                                readOnly={true}
                                showClearDate
                                block
                                isOutsideRange={day => day.isBefore(moment(startDate).startOf('day'))}
                                hideKeyboardShortcutsPanel
                                phrases={datePhrases}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!oneway && (
                    <div className="col-md-3 ">
                      <div className="theme-search-area-section theme-search-area-section-curved ">
                        <div className={this.state.valdate + '  _bg-white _br-5 theme-search-area-section first theme-search-area-section-curved '}>
                          <SingleDatePicker
                            orientation="horizontal"
                            numberOfMonths={1}
                            date={startDate}
                            onDateChange={startDate =>
                              this.setState({ startDate }, () => {
                                this.setPropsToState().then(() => {
                                  this.checkSubmit(false);
                                });
                              })
                            }
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="hidDepartureFill"
                            placeholder={ltrDeparture}
                            displayFormat={() => 'DD-MM-YYYY'}
                            readOnly={true}
                            showClearDate
                            block
                            hideKeyboardShortcutsPanel
                            phrases={datePhrases}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row _mt-10">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-6 ">
                        <div className="theme-search-area-section theme-search-area-section-curved quantity-selector" data-increment="Passengers">
                          <div className="theme-search-area-section-inner">
                            <i className="theme-search-area-section-icon fa fa-users" />
                            <input
                              value={this.state.Passengercount}
                              readOnly={true}
                              type="text"
                              className="theme-search-area-section-input"
                              onClick={this.togglePassenger.bind(this)}
                            />
                            {!this.state.passenger && (
                              <div className="quantity-selector-box">
                                {/* HEADER */}
                                <div className="quantity-selector-inner banner-baslik">
                                  <p className="quantity-selector-title">
                                    <i className="fa fa-users" /> {ltrNeedAssistance}
                                  </p>
                                </div>
                                {/* ADULTS */}
                                <div className="quantity-selector-inner">
                                  <p className="quantity-selector-title">
                                    <i className="fa fa-user" /> {ltrAdult}
                                  </p>
                                  <ul className="quantity-selector-controls">
                                    <li className="quantity-selector-decrement">
                                      <a onClick={this.Decreaseadult.bind(this)}>&#45;</a>
                                    </li>
                                    <li className="quantity-selector-current">{this.state.adult}</li>
                                    <li className="quantity-selector-increment">
                                      <a onClick={this.Incrementadult.bind(this)}>&#43;</a>
                                    </li>
                                  </ul>
                                </div>
                                {/* CHILDREN */}
                                <div className="quantity-selector-inner">
                                  <p className="quantity-selector-title">
                                    <i className="fa fa-user" /> {ltrChild}
                                  </p>
                                  <ul className="quantity-selector-controls">
                                    <li className="quantity-selector-decrement">
                                      <a onClick={this.Decreasechild.bind(this)}>&#45;</a>
                                    </li>
                                    <li className="quantity-selector-current">{this.state.child}</li>
                                    <li className="quantity-selector-increment">
                                      <a onClick={this.Incrementchild.bind(this)}>&#43;</a>
                                    </li>
                                  </ul>
                                </div>
                                {/* BABIES */}
                                <div className="quantity-selector-inner">
                                  <p className="quantity-selector-title">
                                    <i className="fa fa-user" /> {ltrBaby}
                                  </p>
                                  <ul className="quantity-selector-controls">
                                    <li className="quantity-selector-decrement">
                                      <a onClick={this.Decreasebaby.bind(this)}>&#45;</a>
                                    </li>
                                    <li className="quantity-selector-current">{this.state.baby}</li>
                                    <li className="quantity-selector-increment">
                                      <a onClick={this.Incrementbaby.bind(this)}>&#43;</a>
                                    </li>
                                  </ul>
                                </div>
                                {/* OK BUTTON */}
                                <div className="text-right">
                                  <a className="btn btn-bg-default whitebold " onClick={this.PassengerCount.bind(this)}>
                                    {okey}
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <a
                          className={
                            Direct
                              ? 'btn btn-primary-inverse df btn-block theme-coming-soon-form-btn'
                              : 'btn btn-primary-inverse-active df-active btn-block theme-coming-soon-form-btn'
                          }
                          onClick={this.toggleDirect.bind(this)}>
                          <i className={Direct ? 'fa fa-check-circle' : 'fa fa-circle'} /> {radioSearchDirect}
                        </a>
                      </div>
                    </div>
                  </div>
                  <form action="/FlightSearch" onSubmit={this.handleSubmit} noValidate={true}>
                    <div className="col-md-5 ">
                      <a
                        className={
                          Direct
                            ? 'btn btn-primary-inverse-active df-active btn-block theme-coming-soon-form-btn'
                            : 'btn btn-primary-inverse df btn-block theme-coming-soon-form-btn'
                        }
                        onClick={this.toggleAlternate.bind(this)}>
                        <i className={Direct ? 'fa fa-circle' : 'fa fa-check-circle'} /> {radioSearch}
                      </a>
                    </div>

                    <div className="col-md-1 ">
                      <input id="SearchType" name="SearchType" type="hidden" value="1" />
                      <input id="FlightDate" name="FlightDate" type="hidden" value={moment(this.state.startDate).format('YYYY-MM-DD')} />
                      <input id="RetFlightDate" name="RetFlightDate" type="hidden" value={moment(this.state.endDate).format('YYYY-MM-DD')} />
                      <input id="FromAirport" name="FromAirport" type="hidden" value={this.state.departureID} />
                      <input id="ToAirport" name="ToAirport" type="hidden" value={this.state.arrivalID} />
                      <input id="OneWay" name="oneway" type="hidden" value={this.state.oneway ? 0 : 1} />
                      <input id="Adult" name="Adult" type="hidden" value={this.state.adult} />
                      <input id="Child" name="Child" type="hidden" value={this.state.child} />
                      <input id="Baby" name="Baby" type="hidden" value={this.state.baby} />
                      <input id="OnlyDirect" name="OnlyDirect" type="hidden" value={this.state.Direct ? 1 : 0} />
                      <input id="From" name="From" type="hidden" value={this.state.valarri} />
                      <input id="To" name="To" type="hidden" value={this.state.valdep} />
                      <button
                        disabled={this.haveErrors(errors)}
                        type="submit"
                        className="theme-search-area-submit _mt-0 theme-search-area-submit-glow theme-search-area-submit-curved"
                        style={{ display: 'none' }}>
                        <i className="theme-search-area-submit-icon fa fa-angle-right" />
                        <span className="_desk-h">{btnSearch}</span>
                      </button>

                      <Link to={this.state.redirectPath}>
                        <button
                          disabled={this.haveErrors(errors)}
                          type="button"
                          onClick={() => this.checkSubmit(true)}
                          className="theme-search-area-submit _mt-0 theme-search-area-submit-glow theme-search-area-submit-curved">
                          <i className="theme-search-area-submit-icon fa fa-angle-right" />
                          <span className="_desk-h">{btnSearch}</span>
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {submitSuccess && (
              <div className="alert alert-info" role="alert">
                The form was successfully submitted!
              </div>
            )}
            {submitSuccess === false &&
              !this.haveErrors(errors) && (
                <div className="alert alert-danger" role="alert">
                  Sorry, an unexpected error has occurred
                </div>
              )}
            {submitSuccess === false &&
              this.haveErrors(errors) && (
                <div className="alert alert-danger" role="alert">
                  Sorry, the form is invalid. Please review, adjust and try again
                </div>
              )}
          </div>
        </div>
        <Border_soft />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setSystemText, setProp }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SearchFlight2));
